import styles from './Navbar.module.css';
import {SocialIcon} from "react-social-icons";
import config from "../../Config";

function Navbar() {
    return (
        <nav className={config.behavior.useFixedNavBar ? styles.navbarFixed : styles.navbar}>
            <div className={styles.navbarLogoContainer}>
                <div className={styles.logo}>{config.initials}</div>
                <span className={`${styles.logoText} uppercase`}>{config.firstName}</span>
            </div>

            <div className={styles.navbarSocialIconsContainer}>
                <span className={styles.letsConnectText}>
                    Lets connect ➙
                </span>

                <SocialIcon className={styles.iconSize} url={config.socialMediaUrl.linkedin} />
                <SocialIcon className={styles.iconSize} url={config.socialMediaUrl.github} />
                <SocialIcon className={styles.iconSize} url={config.socialMediaUrl.x} />
                <SocialIcon className={styles.iconSize} url={config.socialMediaUrl.instagram} />
            </div>
        </nav>
    );
}
export default Navbar;
