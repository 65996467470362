import Greetings from "../Greetings";
import Headline from "../Headline";
import ConnectiveLine from "../ConnectiveLine";
import Avatar from "../Avatar";


function Hero(){
    return (
        <div className="mt-28 mb-6 sm:mt-20 lg:mt-14 flex flex-col-reverse sm:flex-row items-center group px-5 lg:px-0 overflow-hidden">
            <div className="w-full">
                <Greetings/>
                <Headline/>
            </div>
            <ConnectiveLine/>
            <Avatar/>
        </div>
    );
}

export default Hero;