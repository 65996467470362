import PropTypes from 'prop-types';

const SocialMediaUrlPropTypes = PropTypes.shape({
    linkedin: PropTypes.string,
    github: PropTypes.string,
    instagram: PropTypes.string,
    x: PropTypes.string,
    facebook: PropTypes.string,
    // Add other social media as needed
});

export const ResumePropTypes = PropTypes.shape({
    name: PropTypes.string,
    headline: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    website: PropTypes.string,
    socialMediaUrl: SocialMediaUrlPropTypes,
    skills: PropTypes.arrayOf(PropTypes.shape({
        area: PropTypes.string,
        skills: PropTypes.arrayOf(PropTypes.string),
    })),
    experiences: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        company: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        details: PropTypes.string,
    })),
    projects: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    })),
    education: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    })),
    languages: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        proficiency: PropTypes.string,
    })),
    // Add other fields as needed
});

export const ConfigPropTypes = PropTypes.shape({
    behavior: PropTypes.shape({
        useFixedNavBar: PropTypes.bool,
    }),
    templateType: PropTypes.string, // Or use an enum if you have defined one
    name: PropTypes.string,
    headline: PropTypes.string,
    socialMediaUrl: SocialMediaUrlPropTypes,
    availabilityInfo: PropTypes.arrayOf(PropTypes.object), // Define further if necessary
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    resume: ResumePropTypes,
});
