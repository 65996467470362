import config from "../../Config";

function Footer() {
    return (
        <footer className="text-center mt-16">
            {/* Your footer content. For dynamic year: */}
            <p>&copy; {new Date().getFullYear()} {config.name}</p>
        </footer>
    );
}

export default Footer;
