import React from 'react';

function ResumePaper({ children }) {
    return (
        <div className="bg-gray-200 flex justify-center items-center print:p-0 w-full min-w-[8.5in]">
            <div className="m-[2.5rem] print:m-0 space-y-5 bg-white p-[20mm_15mm] rounded shadow-lg print:shadow-white min-w-[8.5in] min-h-[11in] max-w-[8.5in] max-h-[11in] overflow-hidden">
                {children}
            </div>
        </div>
    );
}


export default ResumePaper;
