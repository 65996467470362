import Availability from "./components/Availability";
import Navbar from "../Navbar";
// import Projects from "../Projects";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import Hero from "./components/Hero";

function Home() {
    return (
        <div className="max-w-6xl mx-auto px-5">
            <Navbar />
            <Hero/>
            <Availability className="mt-28 mb-16 sm:mt-28 lg:mt-14"/>
            {/*<Projects className="my-10"/>*/}
            <ContactMe className="my-10" />
            <Footer className="my-10" />
        </div>
    );
}

export default Home;
