import config from "../../../../Config";
import style from "./Availability.module.css"
import {useNavigate} from "react-router-dom";

function Availability() {
    const navigate = useNavigate();

    function replaceMacros(str) {
        return str.replace(/%email%/g, config.email)
            .replace(/%address%/g, config.address)
            .replace(/%phone%/g, config.phone);
    }

    return (
        <div className={style.mainContainer}>

            {/* Left Side: Text and Button */}
            <div className={style.leftSideContainer}>
                <p>
                    {config.availabilityInfo.map((segment, index) => {
                        if (segment.type === 'text') {
                            return replaceMacros(segment.content);
                        } else if (segment.type === 'link') {
                            return (
                                <a key={index} href={replaceMacros(segment.href)} className="text-blue-500 underline hover:text-blue-700">
                                    {replaceMacros(segment.content)}
                                </a>
                            );
                        } else {
                            return null;
                        }
                    })}
                </p>
                <button className={style.resumeButton} onClick={() => navigate('/resume')}>See Resume ↗</button>
            </div>

            {/* Right Side: Google Maps Embed */}
            <div className={style.rightSideContainer}>
                <iframe
                    width="100%"
                    height="200"
                    src={config.mapURL(config.address)}
                    title="Location on Map"
                    className="border-none"
                ></iframe>
            </div>
        </div>
    );
}


export default Availability;