import config from "../../../../Config";

function Headline() {
    return (
        // old w-full
        <div className="">
            <div className="text-left md:whitespace-nowrap text-2xl sm:text-4xl mt-1 leading-[3.5rem] md:text-4xl md:leading-[4rem] md:my-4 xl:text-6xl font-extrabold font-raleway">
                {config.headline.split('|').map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </div>
        </div>
    );
}


export default Headline;