import React from 'react';
import { faHome, faEnvelope, faMobileScreenButton, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResumeHeader({config}) {
    return (
        <header className="flex items-center justify-between w-full text-sm space-x-10">
            {/* Profile Information */}
            <div className="w-full">
                <h1 className="font-extrabold text-[2.85rem] tracking-tight text-light-800 leading-none">{config.resume.name === "" ? config.name : config.resume.name}</h1>
                <h2 className="pt-1 pl-1 pr-24">{config.resume.headline !== "" ? config.resume.headline : config.headline}</h2>
            </div>

            {/* Contact Info */}
            <ul className="w-80">
                {/* Address */}
                <li className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faHome}/>
                    <p><a className="text-light-700"
                          href={config.addressURL(config.resume.address !== "" ? config.resume.address : config.address)}
                          target="_blank"
                          rel="noopener noreferrer">{config.resume.address !== "" ? config.resume.address : config.address}</a>
                    </p>
                </li>
                <li className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <p>{config.resume.email !== "" ? config.resume.email : config.email}</p>
                </li>

                <li className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faMobileScreenButton}/>
                    <p>{config.resume.phone !== "" ? config.resume.phone : config.phone}</p>
                </li>

                <li className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faGithub}/>
                    <p>{config.displayURL(config.resume.socialMediaUrl.github !== "" ? config.resume.socialMediaUrl.github : config.socialMediaUrl.github)}</p>
                </li>

                <li className="flex items-center space-x-1">
                    <FontAwesomeIcon icon={faEarthAmericas}/>
                    <p>{config.displayURL(config.resume.website)}</p>
                </li>
            </ul>
        </header>
    )
}

export default ResumeHeader;