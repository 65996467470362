import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faEnvelope, faHome, faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { ConfigPropTypes } from "../../../../../ConfigPropTypes";
import { formatText } from "../../resumeFormattingUtils";
import ResumePaper from "../../ResumePaper";
import ResumeHeader from "./ResumeHeader";

const StandardResumeTemplate = ({ config }) => {
    return (
        <>
            <ResumePaper>
                {/* Header */}
                <ResumeHeader config={config}></ResumeHeader>

                {/* Main Content */}
                <main className="flex">
                    {/* Relevant Experience */}
                    <section className="w-full space-y-3 mr-10">
                        <h3 className="font-bold text-light-800 text-lg"> { config.resume.experiences.length > 1 ? "Relevant Experience" : "Last Experience" }</h3>
                        {config.resume.experiences.map((experience, index) => (
                            index < 1 ? (<div key={index} className="space-y-3">
                                    <h4 className="text-sm flex items-center leading-none justify-between">
                                        <div><span className="font-bold">{experience.description}</span> <span className="font-bold">• {experience.company}</span></div>
                                        <p className="font-raleway text-gray-500 text-[13px]">{experience.startDate} - {experience.endDate}</p>
                                    </h4>
                                    <div className="text-[.75rem] justify-between">
                                        {formatText(experience.details)}
                                    </div>
                                </div>
                            ):null))}
                    </section>

                    {/* Skills, Education, Languages */}
                    <div className="w-80 !border-success-700 space-y-5">
                        <section className="space-y-3">
                            <h3 className="font-bold text-light-800 text-lg">Skills</h3>
                            {config.resume.skills.map((skill, index)=>(
                                <div key={index} className="space-y-0">
                                    <h4 className="text-[.75rem] flex items-center leading-none justify-between">
                                        <span className="font-bold">{skill.area}</span>
                                    </h4>
                                    <div className="leading-tight">
                                        {skill.skills.map((areaSkill, skillIndex)=>(
                                            <span key={skillIndex} className="text-[.75rem]">
                                        {areaSkill}{skillIndex < skill.skills.length - 1 ? ', ' : '.'}
                                    </span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </section>
                        {/* Education */}
                        <section className="space-y-3">
                            <h3 className="font-bold text-light-800 text-lg">Education</h3>
                            {config.resume.education.map((education, index)=>(
                                <div key={index} className="space-y-1">
                                    <h4 className="text-[.75rem] flex items-center leading-none justify-between">
                                        <span className="font-bold">{education.title}</span>
                                    </h4>
                                    <div className="text-[.75rem] mt-0 space-y-0">
                                        {education.description}
                                    </div>
                                    <div className="text-[.75rem]">{education.startDate} - {education.endDate}</div>
                                </div>
                            ))}
                        </section>
                        {/* Languages */}
                        <section className="space-y-3">
                            <h3 className="font-bold text-light-800 text-lg">Languages</h3>
                            {config.resume.languages.map((language, index)=>(
                                <div key={index} className="space-y-1">
                                    <h4 className="text-[.75rem] flex items-center leading-none justify-between">
                                        <span className="font-bold">{config.resume.languages[index].name}</span>
                                    </h4>
                                    <div className="text-[.75rem] mt-0 space-y-0">
                                        {config.resume.languages[index].proficiency}
                                    </div>
                                </div>
                            ))}
                        </section>
                    </div>
                </main>

                {/*/!* Footer / Selected Projects *!/*/}
                {/*<footer className="space-y-5">*/}
                {/*    <h3 className="font-bold text-lg"> Selected Projects </h3>*/}
                {/*    <div className="grid grid-cols-3 gap-5">*/}
                {/*        {config.resume.projects.map((project) => (*/}
                {/*            <div className="bg-gray-200 rounded-2xl px-3 space-y-1 p-2 print:bg-gray-200 print-bg-color">*/}
                {/*                <h4 className="font-bold text-sm">{project.name}</h4>*/}
                {/*                <div className="text-[.70rem]">{project.description}</div>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </ResumePaper>
            <ResumePaper>
                {/* Header */}
                <ResumeHeader config={config}></ResumeHeader>

                <main className="flex">

                    {/* Relevant Experience */}
                    <section className="w-full space-y-3 mr-10">
                        <h3 className="font-bold text-light-800 text-lg"> { config.resume.experiences.length > 1 ? "Relevant Experience" : "Last Experience" }</h3>
                        {config.resume.experiences.map((experience, index) => (
                            index > 0 && index <=2 ? (<div key={index} className="space-y-3">
                                    <h4 className="text-sm flex items-center leading-none justify-between">
                                        <div><span className="font-bold">{experience.description}</span> <span className="font-bold">• {experience.company}</span></div>
                                        <p className="font-raleway text-gray-500 text-[13px]">{experience.startDate} - {experience.endDate}</p>
                                    </h4>
                                    <div className="text-[.75rem] justify-between">
                                        {formatText(experience.details)}
                                    </div>
                                </div>
                            ):null))}
                    </section>
                </main>

                {/* Footer / Selected Projects */}
                {/*<footer className="space-y-5">*/}
                {/*    <h3 className="font-bold text-lg"> Selected Projects </h3>*/}
                {/*    <div className="grid grid-cols-3 gap-5">*/}
                {/*        {config.resume.projects.map((project) => (*/}
                {/*            <div className="bg-gray-200 rounded-2xl px-3 space-y-1 p-2 print:bg-gray-200 print-bg-color">*/}
                {/*                <h4 className="font-bold text-sm">{project.name}</h4>*/}
                {/*                <div className="text-[.70rem]">{project.description}</div>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </ResumePaper>
            <ResumePaper>
                {/* Header */}
                <ResumeHeader config={config}></ResumeHeader>

                <main className="flex">

                    {/* Relevant Experience */}
                    <section className="w-full space-y-3 mr-10">
                        <h3 className="font-bold text-light-800 text-lg"> { config.resume.experiences.length > 1 ? "Relevant Experience" : "Last Experience" }</h3>
                        {config.resume.experiences.map((experience, index) => (
                            index > 2 ? (<div key={index} className="space-y-3">
                                    <h4 className="text-sm flex items-center leading-none justify-between">
                                        <div><span className="font-bold">{experience.description}</span> <span className="font-bold">• {experience.company}</span></div>
                                        <p className="font-raleway text-gray-500 text-[13px]">{experience.startDate} - {experience.endDate}</p>
                                    </h4>
                                    <div className="text-[.75rem] justify-between">
                                        {formatText(experience.details)}
                                    </div>
                                </div>
                            ):null))}
                    </section>
                </main>

                {/* Footer / Selected Projects */}
                <footer className="space-y-5">
                    <h3 className="font-bold text-lg"> Selected Projects </h3>
                    <div className="grid grid-cols-3 gap-5">
                        {config.resume.projects.map((project) => (
                            <div className="bg-gray-200 rounded-2xl px-3 space-y-1 p-2 print:bg-gray-200 print-bg-color">
                                <h4 className="font-bold text-sm">{project.name}</h4>
                                <div className="text-[.70rem]">{project.description}</div>
                            </div>
                        ))}
                    </div>
                </footer>
            </ResumePaper>
        </>
    );
};

StandardResumeTemplate.propTypes = {
     config: ConfigPropTypes.isRequired // or any other type that fits your data structure
};

export default StandardResumeTemplate;