// Resume.js
import React, { useState, useEffect } from 'react';
import config from "../../Config";
import TemplateRegistry from "./components/ResumeTemplates/TemplateRegistry";
import {ResumeTemplateTypes} from "./components/ResumeTemplates/ResumeTemplateTypes";


function Resume() {
    const [templateType, setTemplateType] = useState(null);

    useEffect(() => {
        // Set the template type based on configuration
        setTemplateType(config.templateType);
    }, []);

    // Function to render the appropriate template
    const renderTemplate = () => {
        // Retrieve the template component from the registry
        const TemplateComponent = TemplateRegistry[templateType] || TemplateRegistry[ResumeTemplateTypes.STANDARD]; // Fallback to 'standard' if not found
        return <TemplateComponent config={config} />;
    };

    return (
        <>
            {renderTemplate()}
        </>
    );
}

export default Resume;
