import config from "../../../../Config";
function Greetings() {
    return (
        <div className="pt-10 text-l mb-5">
            Hi! 👋 I'm &nbsp;

            <span className="relative font-semibold after:content-[''] after:absolute after:-bottom-3 after:left-0 after:h-3 after:w-full after:rounded-[100%] after:border-[3px] after:border-[#000_transparent_transparent_transparent]">
             {config.name}
            </span>

            &nbsp;&nbsp;and
        </div>);
}

export default Greetings;