// resumeFormattingUtils.js
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export function formatBoldText(text) {
    const parts = text.split('%bold%');
    return (
        <>
            {parts.map((part, index) => (
                index % 2 === 1 ? <span key={index} className="font-bold text-[.65rem]">{part}</span> : part
            ))}
        </>
    );
}

export function processBulletPoints(line, index) {
    const parts = line.split('%bullet%');
    const elements = [];

    if (parts[0].trim() !== '') {
        elements.push(<div key={`text-${index}`} className="mt-1">{formatBoldText(parts[0])}</div>);
    }

    const bulletPoints = parts.slice(1).filter(bp => bp.trim() !== '');
    const bulletElements = bulletPoints.map((bp, bpIndex) => (
        <li key={bpIndex} className="flex items-start">
            <FontAwesomeIcon icon={faCircle} className="text-[.25rem] mr-2 mt-2"></FontAwesomeIcon>
            <span>{formatBoldText(bp)}</span>
        </li>
    ));

    if (bulletElements.length > 0) {
        elements.push(<ul key={`bullet-${index}`} className="list-disc pl-5">{bulletElements}</ul>);
    }

    return elements;
}

export function formatText(text) {
    const elements = [];
    const segments = text.split('%endbullet%');

    segments.forEach((segment, index) => {
        const lines = segment.split('%br%');
        lines.forEach((line, lineIndex) => {
            if (line.includes('%bullet%')) {
                const bulletElements = processBulletPoints(line, lineIndex);
                elements.push(...bulletElements);
            } else {
                elements.push(<div key={`line-${index}-${lineIndex}`} className="mt-2">{formatBoldText(line)}</div>);
            }
        });
    });

    return elements;
}
