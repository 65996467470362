import StandardResumeTemplate from './Standard/StandardResumeTemplate';
// import other templates...

const TemplateRegistry = {
    standard: StandardResumeTemplate,
    // otherTemplateName: OtherTemplateComponent,
    // Add other templates as needed
};

export default TemplateRegistry;
