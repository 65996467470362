import CryptoJS from 'crypto-js';
import config from "../../../../Config";

function Avatar({ alt }) {
   const email = config.email; // replace with your email
   const trimmedEmail = email.trim().toLowerCase();
   const md5Hash = CryptoJS.MD5(trimmedEmail).toString();
   const gravatarURL = `https://www.gravatar.com/avatar/${md5Hash}?s=200`;

   return (
       <img className="rounded-full w-40 mb-3 md:mb-0" src={gravatarURL} alt={alt} />
   );
}

export default Avatar;